import "./App.css";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import { Login } from "./components/login/login";
import { RequireAuth } from "react-auth-kit";
import { Register } from "./components/register";
import { Dashboard } from "./components/dashboard";
import { Blockchain } from "./components/blockchain/blockchain";
import { ExplorerAPI } from "./components/explorerAPI/explorerAPI";
import { MapComp } from "./components/mapComp/mapComp";
import { AuthorizedZone } from "./components/authorizedZone";
import { Home } from "./components/home";
import { CommandCenter } from "./components/commandCenter";

// const AppContainer = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: row;
//   margin: 0 auto;
//   justify-content: center;
//   border: 1px solid yellow;
// `;

function App() {
  return (
    // <AppContainer>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/authorized-zone"
          element={
            <RequireAuth loginPath="/">
              <AuthorizedZone />
            </RequireAuth>
          }
        ></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/commandcenter" element={<CommandCenter />}></Route>
      </Routes>
    // </AppContainer>
  );
}

export default App;
