import styled, { css } from "styled-components";

export const HUDInput = styled.input`
  width: 20vw;
  height: 40px;
  font-size: 24px;
  color: #81affc;
  background-color: black;
  caret-color: #81affc;
  border: 2px solid #81affc;
  margin: 0 auto;
  font-weight: 500;
  font-family: "Rajdhani";//consolas;
  padding: 0px 10px 2px;
  border-radius: 4px;
  border-width: 2px 2px 5px 2px;
  //border-width: 0px 0px 0px 0px;
  position: relative;
  box-shadow: 0 0 5px rgba(0,0,255,.2),
                inset 0 0 5px rgba(0,0,0,.1);
  animation: tracking-in-expand .4s ease-out;

  &&:disabled{
    color: #294065;	
    border-color: #3f5e8a;
    border-width: 2px 2px 2px 2px;
  }


  ${({ glowAnimation }) =>
    glowAnimation &&
    css`
            animation: glow .8s ease-out infinite alternate;
    `}

    ${({ extendAnimation }) =>
    extendAnimation &&
    css`
     &&:disabled{
      color: #81affc;
    border-width: 20px 2px;
    border-color: #81affc;
  }
    width: 320px;
  height: 120px;
  border-width: 20px 2px;
            animation: extend 3s ease-out;
    `}

    /* &&:before{
      position: absolute;
      display: inline-block;
      
      border: 2px solid #81affc;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      border-width: 2px 2px 5px 2px;
      transition: width 0.3s ease, height 0.3s ease;

    } */


  &&:focus{
    outline: none;
    border: 2px solid red;
    border-width: 2px 2px 5px 2px;
    caret-color: #81affc;
    animation: glow .3s ease-out infinite alternate;

  }


  @keyframes glow{
    0%{border-color: #d0fdfe;
    box-shadow: 0 0 5px rgba(0,0,255,.2),
                inset 0 0 5px rgba(0,0,0,.1);}
    100%{border-color: #81affc;
    box-shadow: 0 0 20px rgba(0,0,255,.6),
                inset 0 0 10px rgba(0,0,255,.4);}
}

@keyframes extend{
    0%{width: 20vw;
  height: 40px;
  border-width: 2px 2px 5px 2px;}
    100%{width: 320px;
  height: 120px;
  border-width: 20px 2px;

}
}

@keyframes tracking-in-expand {
  0% {
    width: 0vw;
  }
  100% {
    width: 20vw;
  }
}
`;

export const HackerText = styled.p`
position: relative;
margin: 30px 0 -10px;
color: #d0fdfe;
//border: 1px solid red;
width: 30vw;
font-size: 24px;
height: 48px;
font-weight: 500;
font-family: "Rajdhani";//consolas;
text-align: center;

&:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    content: attr(data-text);
    white-space: nowrap;
    animation: typing .5s steps(16) forwards;
}

@keyframes typing{
    0%{
        width: 0px;
    }
    100%{
        width: 100%;
    }
}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  //border: 1px solid #5d97fd;
  margin: 0 auto;
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 630px;
  height: 30vw;
  //border: 2px solid #5d97fd;
  color: #5d97fd;
`;

export const CancelButton = styled.button`
  border: none;
  
  height: 40px;
  font-size: 24px;
  color: #81affc;
  background-color: black;
  border: 2px solid #81affc;
  margin: 0 auto;
  font-weight: 500;
  font-family: "Rajdhani";//consolas;
  padding: 0px 10px 2px;
  border-radius: 1px;
  border-width: 2px 2px 2px 2px;
  margin-top: 30px;
  width: 20vw;
  cursor: pointer;
`;



