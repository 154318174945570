//@ts-nocheck
import axios, { AxiosError } from "axios";
import { Button } from "baseui/button";
import { HeadingXXLarge } from "baseui/typography";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { Container } from "../commons";
import { useState } from "react";
import { API_URL } from "../../common/Variables";

function AuthorizedZone() {
  const singOut = useSignOut();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [users, setUsers] = useState<any>([]);
  
  const logout = () => {
    singOut();
    navigate("/");
  };

  const getPayment = async () => {
    const response = await axios.get(API_URL+"/api/v1/payment", {
      withCredentials: true,
    });
    console.log("Response: ", response);
  };

  const showUsers = async () => {
    try {
      const response = await axios.post(API_URL+"/api/v1/users");
      setUsers(response.data);
      console.log("USERS: ", response.data);
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError(err.response?.data.message);
      else if (err && err instanceof Error) setError(err.message);
      console.log("Error: ", err);
    }
  };

  const update = async () => {
    try {
      //const values = { fullName: "x" };
      const response = await axios.post(API_URL+"/api/v1/update");
      console.log("UPDATE: ", response);
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError(err.response?.data.message);
      else if (err && err instanceof Error) setError(err.message);
      console.log("Error: ", err);
    }
  };

  const deleteUser = async () => {
    const userValues = { email: "nowy@wp.pl", password: "cos" };
    try {
      const response = await axios.post(
        API_URL+"/api/v1/delete",
        userValues
      );

      console.log("DELETE-", response);
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError(err.response?.data.message);
      else if (err && err instanceof Error) setError(err.message);
      console.log("Error: ", err);
    }
  };

  return (
    <Container>
      <HeadingXXLarge color="secondary500">Welcome Home Bud!</HeadingXXLarge>
      <div style={{ display: "flex" }}>
        <Button
          kind="secondary"
          onClick={getPayment}
          style={{ margin: "10px" }}
        >
          Get Payment
        </Button>
        <p></p>
        <Button kind="secondary" onClick={logout} style={{ margin: "10px" }}>
          Logout
        </Button>
        <p></p>
        <Button kind="secondary" onClick={showUsers} style={{ margin: "10px" }}>
          Users
        </Button>
        <p></p>
        <Button kind="secondary" onClick={update} style={{ margin: "10px" }}>
          Update
        </Button>
        <p></p>
        <Button
          kind="secondary"
          onClick={deleteUser}
          style={{ margin: "10px" }}
        >
          Delete user
        </Button>
        <p></p>
      </div>
      <p></p>
      <table style={{ border: "1px solid black" }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0
            ? users.map((user: any) => (
                <tr>
                  <td style={{ border: "1px solid black" }}>{user.fullName}</td>
                  <td style={{ border: "1px solid black" }}>{user.email}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
  
    </Container>
  );
}

export { AuthorizedZone };
