//@ts-nocheck

import { BackdropImage, StyledBackdrop } from "./styled";

export const Backdrop = () => {
  return (
    <StyledBackdrop>
      <BackdropImage src="./img/landV3.png" alt="backdropImage" />
    </StyledBackdrop>
  );
};
