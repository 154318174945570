//@ts-nocheck

import {
  AboutPanel,
  AboutPanelHeader,
  AboutPanelParagraph,
  StyledAbout,
} from "./styled";
import aboutLabelImg from "./aboutLabel.png";
import economyLabelImg from "./economyLabel.png";

export const About = () => {
  return (
    <StyledAbout id="1">
      <AboutPanel>
        <AboutPanelHeader>
          <img src={aboutLabelImg} />
        </AboutPanelHeader>
        <AboutPanelParagraph>
          Strategic military game initially built on the WAX blockchain, based
          on real geopolitical situation.{" "}
          <p>
            The player takes on the role of the commander of selected armed
            force that allows him to take part in armed conflicts that occur
            around the world. After obtaining the certificate, the commander is
            tasked with building armed forces that will enable him to conduct
            military operations. Conquered cities and completed missions oblige
            commander to collect valuable rewards.
          </p>
          This revolutionary project is intended for a long operational time -
          as long as crypto world exists or even more. Created using
          cutting-edge technologies such as AI generators and modern graphical
          tools, engineered to become game changing market driving force that
          any user can't miss.
        </AboutPanelParagraph>
      </AboutPanel>
      <AboutPanel>
        <AboutPanelHeader>
          <img src={economyLabelImg} />
        </AboutPanelHeader>
        <AboutPanelParagraph>
          Unprecedented economic model in the whole NFT gaming sector that
          assures stable and constant capital worth growth. This has been achieved
          by multiple income source. <br></br>
          <br></br>Profitable user-oriented system features include:
          <ul>
            <li>project development by non-profit organization </li>
            <li>
              in-game rewards funding by ads campaigns displayed on platform / sponsors'
              incentives
            </li>
            <li>0% collection fee on all NFT market transactions</li>
          </ul>
          Such innovative tokenomic scheme has been undiscovered in play-to-earn
          gaming area until now.{" "}
          <p>Join revolution and grab your benefits today!</p>
        </AboutPanelParagraph>
      </AboutPanel>
    </StyledAbout>
  );
};
