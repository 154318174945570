import axios, { AxiosError } from "axios";
import { API_URL } from "../common/Variables";

let x;

export const getLocations = async (setLocations) => {
  try {
    const response = await axios.post(API_URL + "/api/v1/locations");
    setLocations(response.data);
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const getUserActiveForces = async (user, setUserActiveForces) => {
  try {
    const response = await axios.post(API_URL + "/api/v1/active-forces", { user });
    setUserActiveForces(response.data);
    return response.data;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const loadActiveForces = async () => {
  try {
    const response = await axios.post(API_URL + "/api/v1/all-active-forces");
    return response.data;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
}

export const loadCommander = async (wallet) => {
  try {
    const response = await axios.post(API_URL + "/api/v1/commander", { wallet });
    //await new Promise(res=>setTimeout(()=>res("j"),3000)); //async miracle test
    return response;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const commanderExists = async (name) => {
  try {
    const response = await axios.post(API_URL + "/api/v1/commander-exists", { name });
    //await new Promise(res=>setTimeout(()=>res("j"),3000)); //async miracle test
    return response;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const commanderByName = async (name) => {
  try {
    const response = await axios.post(API_URL + "/api/v1/commander-by-name", { name });
    //await new Promise(res=>setTimeout(()=>res("j"),3000)); //async miracle test
    return response;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const emailExists = async (email) => {
  try {
    const response = await axios.post(API_URL + "/api/v1/email-exists", { email });
    //await new Promise(res=>setTimeout(()=>res("j"),3000)); //async miracle test
    return response;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const readUserNfts = async (user, setUserNfts) => {
  try {
    const response = await axios.post(
      API_URL + "/api/v1/explorerapi",
      { user }
    );
    setUserNfts(response.data);
    return response.data;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const deleteActiveForceFromDB = async (id) => {
  const values = { id };
  try {
    const response = await axios.post(
      API_URL + "/api/v1/delete-active-force",
      values
    );
    console.log("DELETE-", response);
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const addActiveForceToDB = async (id, name, locationId, createdAt, nftId, user) => {
  const values = { id, name, locationId, createdAt, nftId, user };
  try {
    const response = await axios.post(
      API_URL + "/api/v1/add-active-force",
      values
    );
    console.log("ADD-", response);
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const addAmmoRequestToDB = async (id, commanderId, amount, createdAt, status) => {
  const values = { id, commanderId, amount, createdAt, status };
  try {
    const response = await axios.post(
      API_URL + "/api/v1/add-ammo-request",
      values
    );
    console.log("ADD-AMMO-DB", response);
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const loadAmmoRequests = async () => {
  try {
    const response = await axios.post(API_URL + "/api/v1/all-ammo-requests");
    return response.data;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
}

export const deleteAmmoRequestFromDB = async (id) => {
  const values = { id };
  try {
    const response = await axios.post(
      API_URL + "/api/v1/delete-ammo-request",
      values
    );
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const addWaxRequestToDB = async (id, commanderId, amount, createdAt, status) => {
  const values = { id, commanderId, amount, createdAt, status };
  try {
    const response = await axios.post(
      API_URL + "/api/v1/add-wax-request",
      values
    );
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const loadWaxRequests = async () => {
  try {
    const response = await axios.post(API_URL + "/api/v1/all-wax-requests");
    return response.data;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
}

export const deleteWaxRequestFromDB = async (id) => {
  const values = { id };
  try {
    const response = await axios.post(
      API_URL + "/api/v1/delete-wax-request",
      values
    );
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};

export const updateCommanderAtDB = async (column, value, commanderId, password, email) => {
  const values = { column, value, commanderId };

  try {
    const response = await axios.post(
      API_URL + "/api/v1/update-commander",
      values
    );
    console.log("upd-", response);
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }

  if (column == "name") {
    const registerValues = { fullName: values.value, email: email, password: password }
    try {
      const response = await axios.post(
        API_URL + "/api/v1/register",
        registerValues
      );
    } catch (err) {
      if (err && err instanceof AxiosError)
        console.log(err.response?.data.message);
      else if (err && err instanceof Error) console.log(err.message);
      console.log("Error: ", err);
    }
  }

};

export const getUserReferrals = async (user, setReferrals) => {
  try {
    const response = await axios.post(API_URL + "/api/v1/referrals", { user });
    setReferrals(response.data);
    return response.data;
  } catch (err) {
    if (err && err instanceof AxiosError)
      console.log(err.response?.data.message);
    else if (err && err instanceof Error) console.log(err.message);
    console.log("Error: ", err);
  }
};
