import { commanderByName, commanderExists, updateCommanderAtDB } from "../../api";
import { commanderStored, walletStored, waxStored } from "../dashboard/data";

export const setNewPlayer = async (name, email, password, referrer, getCommander) => {
    await updateCommanderAtDB("name", name, commanderStored.id, password, email);
    await updateCommanderAtDB("referrer", referrer, commanderStored.id);
    await updateCommanderAtDB("password", password, commanderStored.id);
    await updateCommanderAtDB("email", email, commanderStored.id);
    await updateCommanderAtDB("ecc", 0, commanderStored.id);
    await updateCommanderAtDB("acc", 0, commanderStored.id);
    await updateCommanderAtDB("rcc", 0, commanderStored.id);
    await getCommander();
    if (referrer) {
        const response = await commanderByName(referrer);
        const commanderReferrer = response.data[0];
        await updateCommanderAtDB("acc", commanderReferrer.acc + 100, commanderReferrer.id);
        updateCommanderAtDB("acc", 100, commanderStored.id);
    };
    return true;
};

export const readWaxBalance2 = async (setWaxBalance) => {
    const array = await waxStored.rpc.get_currency_balance(
        "eosio.token",
        walletStored,
        "WAX"
    );
    let waxString = array[0];
    let dotIndex = waxString.indexOf(".");
    if (dotIndex) { setWaxBalance(waxString.slice(0, dotIndex)); } else { setWaxBalance(waxString); };

    //setWaxBalance(array[0]);
};

