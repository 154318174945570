//@ts-nocheck
import {
  AHLogoContainer,
  FooterInfoPanel,
  FooterSocialPanel,
  FooterSpace,
  StyledFooter,
  XLogoContainer,
} from "./styled";
import AHLogo from "./AHLogo.png";
import XLogo from "./Xlogo.png";
import FooterInfo from "./FooterInfo.png";
import { useEffect, useState } from "react";

export const Footer = () => {
  const socialIconStyle = { width: "100%" };
  const footerInfoStyle = { width: "60%" };
  const footerInfoStyleTablet = { width: "80%" };

  const [width, setWidth] = useState(window.innerWidth);
  const tabletScreen = width < 768;
  const phoneScreen = width < 321;
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <StyledFooter>
      <FooterSocialPanel>
        <AHLogoContainer>
          <a href="https://atomichub.io/explorer/collection/wax-mainnet/conflictgame">
            <img src={AHLogo} style={socialIconStyle} />
          </a>
        </AHLogoContainer>
        <XLogoContainer>
          <a href="https://twitter.com/ConflictGame">
            <img src={XLogo} style={socialIconStyle} />
          </a>
        </XLogoContainer>
      </FooterSocialPanel>
      {tabletScreen ? null : <FooterSpace></FooterSpace>}
      <FooterInfoPanel>
        <img src={FooterInfo} style={tabletScreen ? footerInfoStyleTablet : footerInfoStyle} />
      </FooterInfoPanel>
    </StyledFooter>
  );
};
