//@ts-nocheck
import * as waxjs from "@waxio/waxjs/dist";
import { setWalletStored, setWaxStored } from "../components/dashboard/data";

export const initializeWax = () => {
  const wax = new waxjs.WaxJS({
    rpcEndpoint: "https://wax.greymass.com",
  });
  setWaxStored(wax);
  return wax;
};

export async function login(wax: any, setWallet: any) {
  try {
    const userAccount = await wax.login();
    setWallet(userAccount);
    setWalletStored(userAccount);
    return userAccount;
  } catch (e) {}
}

export async function sendWAX(wax, amount) {
  if (!wax.api) {
  }
  try {
    const result = await wax.api.transact(
      {
        actions: [
          {
            account: "eosio.token",
            name: "transfer",
            authorization: [{ actor: wax.userAccount, permission: "active" }],
            data: {
              from: wax.userAccount,
              to: "rvwz.wam",
              quantity: amount + ".00000000 WAX",
              memo: "",
            },
          },
        ],
      },
      { blocksBehind: 3, expireSeconds: 1200 }
    );
    const message = await getCurrentMessage(wax);
    //console.log("MESS"+message,"CONF"+result)
    console.log("TXRESULT");
    console.log(result);
    return JSON.stringify(result);
  } catch (e) {}
}

export async function readWAXBalance(wax, userAccount) {
  try {
    const array = await wax.rpc.get_currency_balance(
      "eosio.token",
      userAccount,
      "WAX"
    );
    const balance = array[0];
    const message = await getCurrentMessage(wax);
    console.log("MESS" + message, "RES" + array);
    return JSON.stringify(balance);
  } catch (e) {
    console.log("TRANSACTION ERROR. " + e);
  }
}

export async function getCurrentMessage(wax) {
  const res = await wax.rpc.get_table_rows({
    json: true,
    code: "test.wax",
    scope: "test.wax",
    table: "messages",
    lower_bound: wax.userAccount,
    upper_bound: wax.userAccount,
  });

  const message = res.rows[0]
    ? res.rows[0].message
    : `<No message is set for ${wax.userAccount}>`;
  return message;
}
