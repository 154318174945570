//@ts-nocheck
import {
  ActivatedRadar,
  CoinBox,
  CoinsPanel,
  CommanderPanel,
  DashboardContainer,
  HackerText,
  LeftPanel,
  MainContainer,
  MainContainerCover,
  NameBox,
  Pad,
  ReferralLabel,
  ReferralsContainer,
  ReferralsNrBox,
  ReferralsPanel,
  RightPanel,
  WalletBox,
  WaxBox,
} from "./styled";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import {
  commanderStored,
  commanderLogged,
  setCommanderStored,
} from "../dashboard/data";
import { waxStored, walletStored } from "../dashboard/data";
import { getUserReferrals, loadCommander } from "../../api";
import { buyAmmoTx, rewardsExchangeTx } from "../dashboard/script";
import { readWaxBalance2 } from "./script";
import { readWAXBalance } from "../../blockchain";
import { useNavigate } from "react-router-dom";

export const CommandCenter = () => {
  const [commander, setCommander] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [referrals, setReferrals] = useState(null);
  const [waxBalance, setWaxBalance] = useState(null);
  const [nameDisplayed, setNameDisplayed] = useState(null);
  const [rightPanelAnimationCover, setRightPanelAnimationCover] =
    useState(true);
  const [leftPanelAnimationCover, setLeftPanelAnimationCover] = useState(true);
  const nameInputRef = useRef();
  const referralInputRef = useRef();
  const navigate = useNavigate();

  readWaxBalance2(setWaxBalance);

  const openModal = (modalType) => {
    setModalType(modalType);
    setModalOpen(true);
  };
  const getCommander = async () => {
    const commander = await loadCommander(walletStored);
    setCommander(commander.data[0]);
    setCommanderStored(commander.data[0]);
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    getCommander();
    setTimeout(() => {
      setRightPanelAnimationCover(false);
      setLeftPanelAnimationCover(false);
    }, 2000);
    setTimeout(() => {
      setNameDisplayed(true);
    }, 2000);
  }, []);

  const buyAmmo = async () => {
    const result = await buyAmmoTx(waxStored, 100);
    setCommander(null);
    getCommander(walletStored);
  };

  const rewardsExchange = async () => {
    const result = await rewardsExchangeTx(100);
    setCommander(null);
    getCommander(walletStored);
  };

  if (!referrals && commander) {
    getUserReferrals(commander.wallet, setReferrals);
  }
  //else { locationActiveForces = userActiveForces.filter((force) => force.locationId === location.id) };

  if (referrals) console.log("REFS: ", referrals);

  if (!commanderLogged) navigate("/login");

  return (
    <>
      <MainContainerCover></MainContainerCover>
      <MainContainer>
        <Pad />
        <DashboardContainer>
          <LeftPanel animationCover={leftPanelAnimationCover}>
            <CommanderPanel>
              <NameBox>
                {nameDisplayed ? (
                  <HackerText data-text={commander?.name} />
                ) : null}
              </NameBox>
              <WalletBox>
                {nameDisplayed ? (
                  <HackerText wallet data-text={"WALLET: " + walletStored} />
                ) : null}
              </WalletBox>
            </CommanderPanel>
            <CoinsPanel>
              <WaxBox>
                {waxBalance && nameDisplayed ? (
                  <HackerText waxbox data-text={waxBalance} />
                ) : null}
              </WaxBox>
              <CoinBox>
                {commander && nameDisplayed ? (
                  <HackerText
                    coin
                    data-text={commander.ecc + " ECC"} //"🌐 " +
                  />
                ) : null}
              </CoinBox>
              <CoinBox itemNr={2}>
                {commander && nameDisplayed ? (
                  <HackerText
                    coin
                    data-text={commander.rcc + " RCC"} //"💎 " +
                  />
                ) : null}
              </CoinBox>
              <CoinBox itemNr={3}>
                {commander && nameDisplayed ? (
                  <HackerText
                    coin
                    data-text={commander.acc + " ACC"} //"🩹 " +
                  />
                ) : null}
              </CoinBox>
            </CoinsPanel>
          </LeftPanel>
          <RightPanel animationCover={rightPanelAnimationCover}>
            <ReferralsPanel>
              <ReferralsNrBox>
                {referrals && nameDisplayed ? referrals.length : "0"}
              </ReferralsNrBox>
              <ReferralsContainer>
                {referrals && nameDisplayed
                  ? referrals.map((referral) => (
                      <div key={referral.name}>
                        {
                          <ReferralLabel>
                            <HackerText
                              referral
                              data-text={referral.name}
                            />
                          </ReferralLabel>
                        }
                      </div>
                    ))
                  : null}
              </ReferralsContainer>
              <ActivatedRadar></ActivatedRadar>
            </ReferralsPanel>
          </RightPanel>

          {/* {commanderLogged ? (
            <>
              COMMANDER<br></br>
              {commander?.name}
              <p>WALLET {walletStored}</p>
              <p>BALANCE {waxBalance}</p>
              {commander ? (
                <>
                  <p></p>
                  <table style={{ border: "1px solid white" }}>
                    <tbody style={{ color: "white" }}>
                      {commander ? (
                        <>
                          <tr>
                            <td style={{ border: "1px solid white" }}>
                              Experience
                            </td>
                            <td style={{ border: "1px solid white" }}>
                              {commander.ecc}
                            </td>
                            <button onClick={() => alert("exchange")}>
                              exchange
                            </button>
                          </tr>
                          <tr>
                            <td style={{ border: "1px solid white" }}>
                              Rewards
                            </td>
                            <td style={{ border: "1px solid white" }}>
                              {commander.rcc}
                            </td>
                            <button onClick={() => rewardsExchange()}>
                              EXCHANGE
                            </button>
                          </tr>
                          <tr>
                            <td style={{ border: "1px solid white" }}>
                              Ammunition
                            </td>
                            <td style={{ border: "1px solid white" }}>
                              {commander.acc}
                            </td>
                            <button onClick={() => buyAmmo()}>BUY AMMO</button>
                          </tr>
                        </>
                      ) : null}
                    </tbody>
                  </table>
                  <p></p>
                  <p></p>
                  <table style={{ border: "1px solid white" }}>
                    <tbody style={{ color: "white" }}>
                      {referrals
                        ? referrals.map((referral) => (
                            <tr key={referral.wallet}>
                              <td style={{ border: "1px solid white" }}>
                                {referral.name}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <p></p>
                </>
              ) : null}
            </>
          ) : <p>COMMANDER LOGGED OUT</p>} */}
        </DashboardContainer>
      </MainContainer>
    </>
  );
};
