export let waxStored = null;
export let walletStored = null;
export let commanderStored = null;
export let commanderLogged = null;

export const setWaxStored = (wax) => {
    waxStored = wax;
}

export const setWalletStored = (wallet) => {
    walletStored = wallet;
}

export const setCommanderStored = (commander) => {
    commanderStored = commander;
}

export const setCommanderLogged = (cLogged) => {
    commanderLogged = cLogged;
}

export const equipmentParams = {
    t80: { combatAmmo: 100, combatPower: 10, },
};