//@ts-nocheck
import styled, { css } from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  //border: 1px solid red;
  margin: 0 auto;
`;

export const StyledNavBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15vw;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: space-evenly;
  //border: 1px solid green;
  z-index: 100;
  overflow: hidden;
  background-image: url("./img/navbar.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const StyledBackdrop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px auto 0px;
  align-items: center;
  justify-content: center;
  //border: 1px solid blue;
`;

export const NavbarImage = styled.img`
  width: 100%;
`;

export const BackdropImage = styled.img`
  width: 100%;
  margin: -10vw auto 0px;
  z-index: -100;
`;

export const StyledNavButton = styled.img`
  width: 8vw;
  margin-top: 1.5vw;
  //border: 2px solid brown;
  cursor: pointer;

  z-index: 200;

  @media (max-width: 767px) {
    width: 14vw;
    margin-top: 0.2vw;
  }
`;

export const SpaceBlock = styled.div.attrs((props: { spaceWidth }) => props)`
  width: 20vw;
`;

export const StyledAbout = styled.section.attrs(
  (props: { columnsNr }) => props
)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 50px 0px;
  border-top: 3px solid #2b3336;
  border-bottom: 3px solid #2b3336;
  background-image: url("./img/aboutBackground.png");
  background-size: cover;
  background-repeat: repeat;
  border-radius: 0px;
  box-shadow: 0px -10px 60px 10px #0b141b;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0px;
  }
`;

export const AboutPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  color: yellow;
  background-color: #000000aa;
  border: 1px solid #2b3336;
  border-radius: 30px;

  @media (max-width: 767px) {
    width: 90vw;
    margin: 10px 0px;
  }
`;

export const AboutPanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  background-color: #2b3336;
  border-radius: 30px 30px 0px 0px;
  opacity: 1;
`;

export const AboutPanelParagraph = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100%-20px);
  margin: 20px;
  flex-wrap: wrap;
  word-break: break-word;
  color: #ffde00;
  font-size: 24px;
  letter-spacing: 0.05em;
  filter: brightness(85%);
  flex-flow: flex-wrap;
`;

export const StyledNFT = styled.section.attrs((props: { rowsNr }) => props)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0px;
`;

export const NFTRow = styled.div.attrs((props: { rowsNr }) => props)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  padding: 20px 0px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const NFTImg = styled.img.attrs((props: { rowsNr }) => props)`
  width: 40vw;
  align-self: center;

  @media (max-width: 767px) {
    width: 90vw;
  }
`;

export const NFTSpace = styled.div`
  width: 5vw;
`;

export const NFTDescription = styled.div.attrs((props: { rowsNr }) => props)`
  display: flex;
  flex-direction: column;
  width: 40vw;
  border: 1px solid #2b3336;
  border-radius: 30px 30px 30px 30px;
  background-color: black;

  @media (max-width: 767px) {
    width: 90vw;
    margin: 0 auto;
  }
`;

export const NFTDescriptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  background-image: url("./img/panelBackgroundGreen.png");
  border-radius: 30px 30px 0px 0px;
`;

export const NFTDescriptionParagraph = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100%-20px);
  margin: 20px;
  flex-wrap: wrap;
  word-break: break-word;
  color: #687267;
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  filter: brightness(130%);
  font-weight: 500;
  flex-flow: flex-wrap;
`;

export const ModalContainer = styled.div.attrs((props: { rowsNr }) => props)`
  display: flex;
  flex-direction: column;
  width: 40vw;
  border: 1px solid #2b3336;
  border-radius: 30px;
  color: yellow;
  background-color: black;
  filter: brightness(150%);

  @media (max-width: 767px) {
    width: 90vw;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  background-color: #2b3336;
  border-radius: 30px 30px 0px 0px;
`;

export const ModalParagraph = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100%-20px);
  margin: 20px;
  flex-wrap: wrap;
  word-break: break-word;
  color: #687267;
  font-size: 20px;
  filter: brightness(130%);
  font-weight: 500;
  flex-flow: flex-wrap;
`;

export const StyledFooter = styled.section.attrs(
  (props: { columnsNr }) => props
)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: 10px 0px;
  border: 3px solid #2b3336;
  background-image: url("./img/aboutBackground.png");
  background-size: cover;
  background-repeat: repeat;
  border-radius: 0px 0px 60px 60px;
  box-shadow: 0px -10px 60px 10px #0b141b;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const FooterSpace = styled.div`
  display: flex;
  flex-direction: row;
  width: 0.2%;
  margin: 20px 0px;
  //border: 1px solid red;
  background-color: #00000011;
`;

export const FooterSocialPanel = styled.div`
  display: flex;
  flex-direction: row;
  width: 43%;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
  padding: 0px 30px;
  background-color: #00000077;
  border-radius: 80px 0px 0px 80px;
  //border: 1px solid red;

  @media (max-width: 767px) {
    justify-content: center;
    margin: 20px 0px 1px 0px;
    width: 86vw;
    border-radius: 80px 80px 0px 0px;
    padding: 0px 0px;
  }
`;

export const AHLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 9%;
  margin: 20px 30px;
  justify-content: space-evenly;
  align-items: center;
  filter: brightness(85%);
  //border: 1px solid white;

  @media (max-width: 767px) {
    width: 14%;
  }
`;

export const XLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 8%;
  margin: 20px 30px;
  justify-content: space-evenly;
  align-items: center;
  filter: brightness(85%);

  @media (max-width: 767px) {
    width: 13%;
  }
`;

export const FooterInfoPanel = styled.div`
  display: flex;
  flex-direction: row;
  width: 43%;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
  padding: 0.7vw 1.2vw 1vw;
  flex-wrap: wrap;
  word-break: break-word;
  color: #ffde00;
  font-size: 24px;
  letter-spacing: 0.05em;
  filter: brightness(90%);
  background-color: #00000077;
  border-radius: 0px 80px 80px 0px;
  //border: 1px solid white;

  @media (max-width: 767px) {
    justify-content: center;
    width: 86vw;
    border-radius: 0px 0px 80px 80px;
    margin: 1px 0px 20px 0px;
    padding: 4vw 0px 4vw;
  }
`;
