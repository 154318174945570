//@ts-nocheck
import React from "react";
import ReactDom from "react-dom";
import { ModalContainer, ModalHeader, ModalParagraph } from "./styled";
import loginPageLabel from "./loginPageLabel.png";
import guideLabel from "./guideLabel.png";

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(0, 0, 0, 0)",
  padding: "50px",
  zIndex: 1000,
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
};

export default function Modal({ open, modalType, closeModal }) {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={closeModal} />
      <div style={MODAL_STYLES} onClick={closeModal}>
        <ModalContainer>
          <ModalHeader>
            <img
              src={
                modalType === "login"
                  ? loginPageLabel
                  : modalType === "guide"
                  ? guideLabel
                  : null
              }
            />
          </ModalHeader>
          <ModalParagraph>COMING SOON ...</ModalParagraph>
        </ModalContainer>
      </div>
    </>,
    document.getElementById("portal")
  );
}
