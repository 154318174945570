//@ts-nocheck
import styled, { css } from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  //border: 1px solid #5d97fd;
  margin: 0 auto;
  background-color: #182c45;
  //background-image: url("/img/DashboardBackground.png");
  //background-repeat: no-repeat;
  //background-size: cover;
  //background-position: center;

  /* @keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */
  /* animation: show 5s linear;

  @keyframes show {
    0% {
      background-color: #182c45;
    }
    100% {
      background-image: url("/img/DashboardBackground.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  } */
`;

export const MainContainerCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  //border: 1px solid #5d97fd;
  margin: 0 auto;
  background-color: #182c45;
  position: absolute;
  background-image: url("/img/DashboardBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;

  animation: show 5s both;
  animation-delay: 2s;

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0 0;
  width: 630px;
  //height: 30vw;
  //border: 2px solid #5d97fd;
  color: #5d97fd;
  position: relative;
  //background-color: #182c45;
  //border: 1px solid orange;
`;

export const Pad = styled.div`
  margin: 0 auto;
  padding: 0 0;
  width: 688px;
  height: 547px;
  background-image: url("/img/Pad.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: calc(100vh / 2 - 272px);
  animation: show 5s both;
  animation-delay: 2s;
  opacity: 0;

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0;
  padding: 0 0;
  width: 335px;
  height: 362px;
  //border: 1px solid orange;
  color: #5d97fd;
  position: relative;
  //background-color: #182c45;

  ${({ animationCover }) =>
    animationCover &&
    css`
      &:before {
        position: absolute;
        top: -165px;
        width: 335px;
        height: 300px;
        background-color: #182c45;
        content: "";
        z-index: 20;
      }
    `}
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0;
  padding: 0 0;
  width: 294px;
  height: 482px;
  //border: 1px solid orange;
  color: #5d97fd;
  position: relative;
  //background-color: #182c45;

  ${({ animationCover }) =>
    animationCover &&
    css`
      &:before {
        position: absolute;
        left: -294px;
        width: 294px;
        height: 482px;
        background-color: #182c45;
        //border: 1px solid red;
        content: "";
        z-index: 5;
      }
    `}
`;

export const ReferralsPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0;
  padding: 0 0;
  width: 294px;
  height: 482px;
  //border: 1px solid blue;
  color: #5d97fd;
  position: absolute;
  z-index: 4;
  animation: swing-right-bck 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  transform: rotateY(-180deg);
  left: 0px;
  top: 0px;
  overflow: hidden;

  @keyframes swing-right-bck {
    0% {
      transform: rotateY(-180deg);
      transform-origin: left;
    }
    100% {
      transform: rotateY(0deg);
      transform-origin: left;
    }
  }

  background-image: url("./img/referralsPanel.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const ReferralsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0 0;
  padding: 0 0 0 0;
  width: 230px;
  height: 218px;
  //border: 1px solid green;
  position: absolute;
  left: 32px;
  top: 75px;
  overflow: hidden;
`;

export const ReferralLabel = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0;
  padding: 0 0;
  width: 230px;
  height: 28px;
  //border: 1px solid blue;
  overflow: hidden;
  overflow-wrap: anywhere;
`;

export const ActivatedRadar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0;
  padding: 0px 0px 0px;
  width: 59px;
  height: 59px;
  //border: 1px solid red;
  color: #5d97fd;
  z-index: 40;
  position: absolute;
  left: 221px;
  top: 404px;

  background-image: url("./img/radarGIFTransparent.gif");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const CommanderPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0;
  padding: 0px 0px 0px;
  width: 335px;
  height: 165px;
  //border: 1px solid red;
  color: #5d97fd;
  //background-color: #182c45;
  z-index: 30;
  position: absolute;
  left: -1px;
  top: -1px;

  background-image: url("./img/commanderPanel.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const CoinsPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0;
  padding: 0 0;
  width: 335px;
  height: 197px;
  //border: 1px solid blue;
  color: #5d97fd;
  animation: extendDown 1s ease-out;
  position: absolute;
  top: 165px;
  left: -1px;
  z-index: 10;
  //animation: swing-right-bck 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  //transform: rotateY(-180deg);
  //left: 319px;
  //top: 119px;

  @keyframes extendDown {
    0% {
      top: -32px;
      filter: brightness(50%);
    }
    100% {
      top: 165px;
      filter: brightness(100%);
    }
  }

  @keyframes swing-right-bck {
    0% {
      transform: rotateY(-180deg);
      transform-origin: left;
    }
    100% {
      transform: rotateY(0deg);
      transform-origin: left;
    }
  }

  background-image: url("./img/coinsPanel.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
`;

export const NameBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0;
  width: 260px;
  height: 36px;
  //border: 1px solid orange;
  position: absolute;
  top: 52px;
  left: 40px;
  overflow: hidden;
  overflow-wrap: normal;
`;

export const WalletBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0;
  width: 260px;
  height: 30px;
  //border: 1px solid orange;
  position: absolute;
  top: 84px;
  left: 40px;
  overflow: hidden;
  overflow-wrap: normal;
`;

export const CoinBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0;
  width: 150px;
  height: 30px;
  //border: 1px solid orange;
  position: absolute;
  top: 42px;
  left: 60px;
  overflow: hidden;
  overflow-wrap: normal;

  ${({ itemNr }) =>
    itemNr == 2 &&
    css`
      top: 74px;
    `}
  ${({ itemNr }) =>
    itemNr == 3 &&
    css`
      top: 106px;
    `}
`;

export const WaxBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0;
  width: 110px;
  height: 24px;
  //  border: 1px solid orange;
  position: absolute;
  top: 4px;
  left: 114px;
  overflow: hidden;
  overflow-wrap: normal;
`;

export const ReferralsNrBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0;
  width: 54px;
  height: 31px;
  //border: 1px solid orange;
  position: absolute;
  top: 17px;
  left: 27px;
  overflow: hidden;
  overflow-wrap: normal;
  color: #1c3569;
  font-weight: 700;
  font-size: 22px;
`;

export const HackerText = styled.p`
  position: relative;
  color: #76a3e6;
  width: inherit;
  font-size: 24px;
  height: 28px;
  font-weight: 500;
  letter-spacing: 1.5px;
  font-family: "Rajdhani"; //consolas;
  filter: brightness(110%);

  ${({ wallet }) =>
    wallet &&
    css`
      font-size: 16px;
      height: 20px;
      letter-spacing: 0.1px;
      font-weight: 400;
    `}

  ${({ waxbox }) =>
    waxbox &&
    css`
      font-size: 22px;
      letter-spacing: 0.1px;
      font-weight: 500;
      text-align: center;
      height: 26px;
    `}

    ${({ coin }) =>
    coin &&
    css`
      font-size: 18px;
      letter-spacing: 0.1px;
      font-weight: 500;
      height: 26px;
    `}

    ${({ referral }) =>
    referral &&
    css`
      font-size: 18px;
      height: 26px;
      letter-spacing: 0.1px;
      font-weight: 500;
    `}

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    content: attr(data-text);
    white-space: nowrap;
    animation: typing 0.8s steps(16) forwards;
  }

  @keyframes typing {
    0% {
      width: 0px;
    }
    100% {
      width: 100%;
    }
  }
`;
