//@ts-nocheck
import { SpaceBlock, StyledNavBar, StyledNavButton } from "./styled";
import navButtonImgMarket from "./rankButtonMarket.png";
import navButtonImgGuide from "./rankButtonGuide.png";
import navButtonImgAbout from "./rankButtonAbout.png";
import navButtonImgLogin from "./rankButtonLogin.png";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { waxLogin } from "./script";

export const Navbar = ({ openModal }) => {
  const navButtonMarketRef = useRef();
  const navButtonGuideRef = useRef();
  const navButtonAboutRef = useRef();
  const navButtonLoginRef = useRef();
  const navigate = useNavigate();
  const [wallet, setWallet] = useState(null);

  useEffect(() => {
    if (wallet) {
      navigate("/login");
    }
  }, [wallet]);

  return (
    <>
      <StyledNavBar>
        <a href="https://atomichub.io/market?primary_chain=wax-mainnet&collection_name=conflictgame">
          <StyledNavButton
            key="market"
            ref={navButtonMarketRef}
            src={navButtonImgMarket}
            onMouseOver={() => {
              navButtonMarketRef.current.src =
                "./img/rankButtonMarketHover.png";
            }}
            onMouseLeave={() => {
              navButtonMarketRef.current.src = "./img/rankButtonMarket.png";
            }}
            onClick={() => {
              navButtonMarketRef.current.src =
                "./img/rankButtonMarketClick.png";
            }}
          ></StyledNavButton>
        </a>

        <StyledNavButton
          key="guide"
          ref={navButtonGuideRef}
          src={navButtonImgGuide}
          onMouseOver={() => {
            navButtonGuideRef.current.src = "./img/rankButtonGuideHover.png";
          }}
          onMouseLeave={() => {
            navButtonGuideRef.current.src = "./img/rankButtonGuide.png";
          }}
          onClick={() => {
            navButtonGuideRef.current.src = "./img/rankButtonGuideClick.png";
            openModal("guide");
          }}
        ></StyledNavButton>
        <SpaceBlock width="100px"></SpaceBlock>
        <a href="#1">
          <StyledNavButton
            key="about"
            ref={navButtonAboutRef}
            src={navButtonImgAbout}
            onMouseOver={() => {
              navButtonAboutRef.current.src = "./img/rankButtonAboutHover.png";
            }}
            onMouseLeave={() => {
              navButtonAboutRef.current.src = "./img/rankButtonAbout.png";
            }}
            onClick={() => {
              navButtonAboutRef.current.src = "./img/rankButtonAboutClick.png";
            }}
          ></StyledNavButton>
        </a>
        <StyledNavButton
          key="login"
          ref={navButtonLoginRef}
          src={navButtonImgLogin}
          onMouseOver={() => {
            navButtonLoginRef.current.src = "./img/rankButtonLoginHover.png";
          }}
          onMouseLeave={() => {
            navButtonLoginRef.current.src = "./img/rankButtonLogin.png";
          }}
          onClick={() => {
            navButtonLoginRef.current.src = "./img/rankButtonLoginClick.png";
            waxLogin(setWallet);
            // navigate("/commandcenter");
            //openModal("login");
          }}
        ></StyledNavButton>
      </StyledNavBar>
    </>
  );
};
