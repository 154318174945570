//@ts-nocheck
import { Button } from "baseui/button";
import { Input } from "baseui/input";
import styled from "styled-components";
import {
  HeadingXXLarge,
  HeadingXLarge,
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
} from "baseui/typography";
import {
  Container,
  ErrorText,
  InnerContainer,
  InputWrapper,
  StyledInput,
} from "../commons";

import { useSignIn } from "react-auth-kit";
import { useFormik } from "formik";
import axios, { AxiosError } from "axios";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CancelButton,
  HUDInput,
  HackerText,
  MainContainer,
  RegisterContainer,
} from "./styled";
import { setNewPlayer } from "../commandCenter/script";
import {
  commanderLogged,
  commanderStored,
  setCommanderLogged,
  setCommanderStored,
  walletStored,
} from "../dashboard/data";
import { commanderExists, emailExists, loadCommander } from "../../api";
import { API_URL } from "../../common/Variables";

function Login(props: any) {
  const [error, setError] = useState("");
  const signIn = useSignIn();
  const navigate = useNavigate();

  const [name, setName] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [referrer, setReferrer] = useState(null);
  const [emptyReferrer, setEmptyReferrer] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [loginPassword, setLoginPassword] = useState(null);
  const [commander, setCommander] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [referrerError, setReferrerError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmError, setConfirmError] = useState(null);
  const [loginPasswordError, setLoginPasswordError] = useState(null);
  const [commanderRegistered, setCommanderRegistered] = useState(null);

  const nameInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const referrerInputRef = useRef(null);
  const confirmInputRef = useRef(null);
  const loginPasswordInputRef = useRef(null);
  const [glowAnimation, setGlowAnimation] = useState(false);
  const [extendAnimation, setExtendAnimation] = useState(false);

  const getCommander = async () => {
    const commander = await loadCommander(walletStored);
    console.log("DSfdsf");
    console.log(commander);
    setCommander(commander.data[0]);
    setCommanderStored(commander.data[0]);
  };

  const validateName = async (inputName) => {
    const re = /^[a-z 0-9]+$/i;
    if (!re.test(inputName))
      return setNameError("Incorrect name, letters and numbers allowed only");
    if (inputName.length > 40)
      return setNameError("Incorrect name, max 40 characters");
    const commanderNameExists = await commanderExists(inputName);
    if (commanderNameExists.data) {
      return setNameError("Commander name already exists.");
    }
    setNameError(null);
    nameInputRef.current.disabled = true;
    setName(inputName);
  };

  const validateReferrer = async (inputName) => {
    if (inputName.length > 0) {
      const re = /^[a-z 0-9]*/i;
      if (!re.test(inputName))
        return setReferrerError(
          "Incorrect referrer name, letters and numbers allowed only"
        );
      if (inputName.length > 40)
        return setReferrerError("Incorrect referrer name, max 40 characters");

      const referrerExists = await commanderExists(inputName);
      if (!referrerExists.data) {
        return setReferrerError("Referrer name not exists.");
      }
      setReferrerError(null);
      referrerInputRef.current.disabled = true;
      setReferrer(inputName);
      setConfirm("ok");
    } else {
      setReferrerError(null);
      referrerInputRef.current.disabled = true;
      setReferrer("");
      setEmptyReferrer(true);
      setConfirm("ok");
    }
  };

  const validateEmail = async (inputName) => {
    const re1 = /^[-.@_a-z0-9]+$/i;
    const re2 =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!re1.test(inputName) || !re2.test(inputName))
      return setEmailError("Incorrect e-mail.");
    const emailExistsFlag = await emailExists(inputName);
    if (emailExistsFlag.data) {
      return setEmailError("E-mail already exists.");
    }
    setEmailError(null);
    emailInputRef.current.disabled = true;
    setEmail(inputName);
  };

  const validatePassword = async (inputName) => {
    const re = /^[!@#$%^&*a-z 0-9]+$/i;
    if (!re.test(inputName))
      return setPasswordError("Invalid password, allowed symbols: !@#$%^&*");
    if (inputName.length < 5 || inputName.length > 40)
      return setPasswordError("Invalid password, min 5 / max 40 characters");
    setPasswordError(null);
    passwordInputRef.current.disabled = true;
    setPassword(inputName);
  };

  useEffect(() => {
    setTimeout(() => {
      setGlowAnimation(true);
    }, 400);
    walletStored ? getCommander() : navigate("/home");
  }, []);

  const signInCommandCenter = async (values: any) => {
    setError("");
    try {
      const response = await axios.post(API_URL + "/api/v1/login", values);

      console.log("LoginResponse: ", response);

      signIn({
        token: response.data.token,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState: { email: values.email },
      });
      setLoginPasswordError(null);
      setCommanderLogged(true);
      //setExtendAnimation(true);
      await new Promise((resolve) =>
        setTimeout(() => {
          navigate("/commandcenter");
        }, 1000)
      );
      //navigate("/commandcenter");
    } catch (err) {
      setLoginPasswordError("Invalid password.");
      loginPasswordInputRef.current.disabled = false;
      confirmInputRef.current.disabled = false;
      if (err && err instanceof AxiosError)
        setError(err.response?.data.message);
      else if (err && err instanceof Error) setError(err.message);
      console.log("Error: ", err);
    }
  };

  const signInProcess = async (confirmationPassword) => {
    confirmInputRef.current.disabled = true;
    await setNewPlayer(name, email, password, referrer, getCommander);
    signInCommandCenter({
      email: email,
      password: confirmationPassword,
    });
  };

  const loginProcess = async (confirmationPassword) => {
    signInCommandCenter({
      email: commander.email,
      password: confirmationPassword,
    });
  };

  // const formik = useFormik({
  //   initialValues: {
  //     fullName: "",
  //     password: "",
  //   },
  //   onSubmit,
  // });

  const playKeyboardSound = async () => {
    let sound = new Audio();
    sound.src = "keyboard.wav";
    sound.play();
  };

  const playKeySound = async () => {
    let sound = new Audio();
    sound.src = "click.wav";
    sound.play();
  };

  const playClickSound = async () => {
    loginPasswordInputRef.current.onhover = () => {
      let sound = new Audio();
      sound.src = "click.wav";
      sound.play();
    };
  };

  useEffect(() => {
    if (commander?.name || loginPasswordError) {
      loginPasswordInputRef.current?.focus();
    } else {
      nameInputRef.current?.focus();
    }
    if (name) {
      passwordInputRef.current?.focus();
    }
    if (password) {
      emailInputRef.current?.focus();
    }
    if (email) {
      referrerInputRef.current?.focus();
    }
    if (referrer || emptyReferrer) {
      confirmInputRef.current?.focus();
    }
  }, [
    name,
    password,
    email,
    referrer,
    emptyReferrer,
    commander,
    loginPasswordError,
  ]);

  return (
    <MainContainer>
      <RegisterContainer>
        {commander?.name && !commanderRegistered ? (
          <>
            <HackerText data-text={"Enter password"} />
            <HUDInput
              type="password"
              glowAnimation={glowAnimation}
              extendAnimation={extendAnimation}
              ref={loginPasswordInputRef}
              onKeyDown={(e) => {
                playKeySound();
                if (e.code === "Enter") {
                  setLoginPassword(e.target.value);
                  loginPasswordInputRef.current.disabled = true;
                  loginProcess(e.target.value);
                }
              }}
              // onLoad={(e) => {
              //   playKeyboardSound();
              // }}
            />
            {loginPasswordError ? (
              <HackerText data-text={loginPasswordError} />
            ) : null}
          </>
        ) : (commander && !commander?.name) || commanderRegistered ? (
          <>
            {!confirm ? (
              <>
                <HackerText data-text={"ENTER your name"} />
                <HUDInput
                  glowAnimation={glowAnimation}
                  ref={nameInputRef}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") validateName(e.target.value);
                  }}
                />
                {nameError ? <HackerText data-text={nameError} /> : null}
              </>
            ) : null}

            {name && !confirm ? (
              <>
                <HackerText data-text={"Set password"} />
                <HUDInput
                  type="password"
                  glowAnimation={glowAnimation}
                  ref={passwordInputRef}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") validatePassword(e.target.value);
                  }}
                />
                {passwordError ? (
                  <HackerText data-text={passwordError} />
                ) : null}
              </>
            ) : null}

            {password && !confirm ? (
              <>
                <HackerText data-text={"Enter your e-mail"} />
                <HUDInput
                  glowAnimation={glowAnimation}
                  ref={emailInputRef}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") validateEmail(e.target.value);
                  }}
                />
                {emailError ? <HackerText data-text={emailError} /> : null}
              </>
            ) : null}

            {email && !confirm ? (
              <>
                <HackerText data-text={"Enter referrer name - if any"} />
                <HUDInput
                  glowAnimation={glowAnimation}
                  ref={referrerInputRef}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      validateReferrer(e.target.value);
                    }
                  }}
                />
                {referrerError ? (
                  <HackerText data-text={referrerError} />
                ) : null}
              </>
            ) : null}

            {referrer || emptyReferrer ? (
              <>
                <HackerText data-text={"Name: " + name} />
                <HackerText data-text={"E-mail: " + email} />
                <HackerText data-text={"Referrer: " + referrer} />
                <p></p>
                <HackerText data-text={"Confirm password"} />
                <HUDInput
                  type="password"
                  glowAnimation={glowAnimation}
                  extendAnimation={extendAnimation}
                  ref={confirmInputRef}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      if (e.target.value === password) {
                        setCommanderRegistered(true);
                        setConfirmError(null);
                        signInProcess(e.target.value);
                      } else {
                        setConfirmError("Password doesn't match.");
                      }
                    }
                  }}
                  // onLoad={(e) => {
                  //   confirmInputRef.current.focus();
                  // }}
                />
                {confirmError ? <HackerText data-text={confirmError} /> : null}
                <CancelButton
                  onClick={() => {
                    setName(null);
                    setEmail(null);
                    setPassword(null);
                    setReferrer(null);
                    setEmptyReferrer(null);
                    setConfirm(null);
                    setConfirmError(null);
                    nameInputRef.current.disabled = false;
                    passwordInputRef.current.disabled = false;
                    emailInputRef.current.disabled = false;
                    referrerInputRef.current.disabled = false;
                    confirmInputRef.current.disabled = false;
                  }}
                >
                  CANCEL
                </CancelButton>
              </>
            ) : null}
          </>
        ) : "Connection error"}
      </RegisterContainer>
    </MainContainer>

    // <Container>
    //   <InnerContainer>
    //     <form onSubmit={formik.handleSubmit}>
    //       <HeadingXXLarge>Welcome Back!</HeadingXXLarge>
    //       <ErrorText>{error}</ErrorText>
    //       <InputWrapper>
    //         <StyledInput
    //           name="fullName"
    //           value={formik.values.fullName}
    //           onChange={formik.handleChange}
    //           placeholder="Name"
    //           clearOnEscape
    //           size="large"
    //         />
    //       </InputWrapper>
    //       <InputWrapper>
    //         <StyledInput
    //           name="password"
    //           value={formik.values.password}
    //           onChange={formik.handleChange}
    //           placeholder="Password"
    //           clearOnEscape
    //           size="large"
    //           type="password"
    //         />
    //       </InputWrapper>
    //       <InputWrapper>
    //         <Button size="large" kind="primary" isLoading={formik.isSubmitting}>
    //           Login
    //         </Button>
    //       </InputWrapper>
    //     </form>
    //   </InnerContainer>
    // </Container>
  );
}

export { Login };
