//@ts-nocheck
import { Col, Row } from "react-bootstrap";
import { Motion } from "./Motion";
import { Backdrop } from "./backdrop";
import { Navbar } from "./navbar";
import { MainContainer } from "./styled";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { About } from "./about";
import { NFT } from "./nft";
import Modal from "./modal";
import { Footer } from "./footer";

export const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(false);
  const openModal = (modalType) => {
    setModalType(modalType);
    setModalOpen(true);
  };
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <MainContainer>
        <Navbar openModal={openModal}></Navbar>
        <Backdrop></Backdrop>
        <About></About>
        <NFT></NFT>
        <Modal
          open={modalOpen}
          modalType={modalType}
          closeModal={() => setModalOpen(false)}
        />
        <Footer></Footer>
      </MainContainer>
    </>
  );
};
