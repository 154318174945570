//@ts-nocheck
import { useEffect, useState } from "react";
import {
  NFTDescription,
  NFTDescriptionHeader,
  NFTDescriptionParagraph,
  NFTImg,
  NFTRow,
  NFTSpace,
  StyledNFT,
} from "./styled";
import AOS from "aos";
import "aos/dist/aos.css";
import tankLabel from "./tankLabel.png";
import artilleryLabel from "./artilleryLabel.png";
import fighterLabel from "./fighterLabel.png";
import droneLabel from "./droneLabel.png";

export const NFT = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const tabletScreen = width < 768;
  const phoneScreen = width < 321;
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <StyledNFT>
      <NFTRow>
        {tabletScreen ? null : (
          <>
            <NFTImg
              src="./img/tankNFT.png"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-offset="200"
            />
            <NFTSpace></NFTSpace>
          </>
        )}
        <NFTDescription>
          <NFTDescriptionHeader>
            <img style={{ filter: "brightness(130%)" }} src={tankLabel} />
          </NFTDescriptionHeader>
          <NFTDescriptionParagraph>
            Heavy armored vehicle built for versatility and survivability
            equipped with powerful main gun that can fire a variety of rounds.
            <p>
              Features robust engines and tracks that allow it to traverse
              rugged terrain, cross water obstacles, and maintain high speeds on
              roads.
            </p>
            Designed for a wide range of combat scenarios, tank is well-balanced
            in terms of firepower, armor, and mobility, can engage a wide range
            of targets effectively, from enemy tanks and fortified positions to
            infantry and light vehicles on the front line.
          </NFTDescriptionParagraph>
        </NFTDescription>
        {tabletScreen ? (
          <>
            <NFTImg
              src="./img/tankNFT.png"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-offset="200"
            />
          </>
        ) : null}
      </NFTRow>
      <NFTRow>
        <NFTDescription>
          <NFTDescriptionHeader>
            <img style={{ filter: "brightness(130%)" }} src={artilleryLabel} />
          </NFTDescriptionHeader>
          <NFTDescriptionParagraph>
            Military ground unit that provides devastating firepower and
            long-range support to ground forces. Possess unparalleled firepower,
            capable of delivering a wide variety of munitions over great
            distances.
            <p>
              Its primary armament, such as cannons, howitzers, and rocket
              launchers, can launch projectiles with pinpoint accuracy or
              saturate an area with high-explosive or precision-guided
              munitions.
            </p>
            This overwhelming firepower allows artillery to wreak havoc behind
            enemy lines, engage various targets, from armored vehicles,
            fortified positions to ammunition depots and command headquarters,
            providing a critical advantage on the battlefield.
          </NFTDescriptionParagraph>
        </NFTDescription>
        <NFTSpace></NFTSpace>
        <NFTImg
          src="./img/artilleryNFT.png"
          data-aos="fade-left"
          data-aos-duration="3000"
          data-aos-offset="200"
        />
      </NFTRow>
      <NFTRow>
        {tabletScreen ? null : (
          <>
            <NFTImg
              src="./img/droneNFT.png"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-offset="200"
            />
            <NFTSpace></NFTSpace>
          </>
        )}
        <NFTDescription>
          <NFTDescriptionHeader>
            <img style={{ filter: "brightness(130%)" }} src={droneLabel} />
          </NFTDescriptionHeader>
          <NFTDescriptionParagraph>
            In the ever-evolving landscape of modern warfare, combat drones have
            emerged as a game-changing technology. Unmanned Aerial Vehicle
            (UAVs), armed with advanced sensors and weaponry, offer a host of
            advantages that are transforming the way military operations are
            conducted.{" "}
            <p>
              It can engage in reconnaissance and intelligence gathering,
              conduct precision strikes, provide support to ground forces, and
              even disrupt enemy electronic systems through electronic warfare
              capabilities.
            </p>{" "}
            Its versatility makes it valuable asset in a wide range of missions
            that can bring the most unexpected effects.
          </NFTDescriptionParagraph>
        </NFTDescription>
        {tabletScreen ? (
          <>
            <NFTImg
              src="./img/droneNFT.png"
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-offset="200"
            />
          </>
        ) : null}
      </NFTRow>
      <NFTRow>
        <NFTDescription>
          <NFTDescriptionHeader>
            <img style={{ filter: "brightness(130%)" }} src={fighterLabel} />
          </NFTDescriptionHeader>
          <NFTDescriptionParagraph>
            Apex predator of the sky, rules the battlefield with its unmatched
            speed, agility, and firepower. Equipped with advanced targeting
            systems and munitions that allow for precision strikes, has mobility
            to reach distant areas in a short time, whether for air-to-air
            combat, close air support, or reconnaissance missions.{" "}
            <p>
              It can engage a wide range of targets, including enemy aircraft,
              armored vehicles, ground installations, and naval vessels.
            </p>
            The presence of fighter aircraft can serve as a powerful strategic
            deterrent as the embodiment of air power and aerial dominance
            influencing the outcome of conflicts.
          </NFTDescriptionParagraph>
        </NFTDescription>
        <NFTSpace></NFTSpace>
        <NFTImg
          src="./img/fighterNFT.png"
          data-aos="fade-left"
          data-aos-duration="3000"
          data-aos-offset="200"
        />
      </NFTRow>
    </StyledNFT>
  );
};
